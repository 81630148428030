.maindata{ 
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.datatoadd { 
  flex:100%;
  width: 100%;
  height: 100%;
  border: 1px solid #04384a;
  border-radius: 5px;
}

.subdomain { 
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
}
.space { 
  height: 40px;
}

.card1{
display: flex;
flex-direction: column;
position: fixed;
left: 0px;
top: 45%;
gap: 0.2rem;
}
.btnIcon1{
width: 8rem;
}
.carouselicon1{
height: 3.125rem;
width: 3.125rem;
align-self: center;
}
.titreES1{
font-size: 1.4rem;
}
  #form-group{
      display:flex;
      align-items:center;
      margin-bottom:20px;
      padding: 10px;
    }
    #form-group label{
      flex:1;
      flex-basis:40%;
      max-width:60%;
      font-size: small;
    }
    #form-group input {
      flex:2;
      flex-basis:40%;
      max-width:60%;
      font-size:small;
     
    }
    #form-group select {
      flex:2;
      flex-basis:40%;
      max-width:60%;
    }
    #form-group button {
      flex:2;
      flex-basis:40%;
      max-width:60%;
    }
    .charger_{
      background-color: grey;
      font-size: 0.8rem;
      margin-top: 1rem;
    }
#printable-content{
margin-top: 0;
padding-top: 2rem;
width: 100%; 
display: flex; 
justify-content: center; 
/* align-items: center;  */
height: 100%;
}
.donnee{
width: 80%;
display: flex;
justify-content: center;
align-items: center;
}
.conteneur{
width: 100%; 
display: block;
justify-content: center; 
align-items: center;
}
.conteneur2{
width: 100%;
display: flex;
justify-content: center;
align-items: center; 
gap: 5rem; 
margin-bottom: 3rem; 
background-color: white; 
padding: 2rem;
border: 1.5px solid #289642;
border-radius: 5px;
}
.bouton4{
background-color: #289642;
border: none;
border-radius: 5px;
height: 7vh;
color: white;
width: 20vw;
font-weight: bold;
}
.reset{
cursor: pointer;
outline: 1px solid white;
border-radius: 50%;
box-shadow: 3px 3px 2px black;
width: 7rem;
height: 7rem;
}
.reset:hover{
cursor: pointer;
outline: 1px solid white;
border-radius: 50%;
box-shadow: 3px 3px 2px black;
width: 8rem;
height: 8rem;
}
.div-reset{
width: 50%;
display: flex;
justify-content: center;
align-items: center;
padding: 1.5rem;
}
.global{
width: 100%;
display: flex;
justify-content: center;
align-items: center;
margin-bottom: 3rem;
border: 1px solid black;
}
.moitie1{
width: 90%;
display: block;
justify-content: center;
border-right: 1px solid black;
padding: 2rem;
}
.sous-moitie{
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}
.moitie2{
display: flex;
justify-content: center;
align-items: center;
margin-top: 2rem;
}
.multiple-choix{
width: 50%;
}
.bouton-choix{
background-color: #258BAF; 
color: white; 
width: 75%; 
height: 6vh; 
border-radius: 5px; 
font-weight: bold;
}
.rappel{
text-align: center;
font-size: 1rem;
}
.telecharger{
background-color: #289642;
font-size: 0.8rem;
margin-top: 1rem;
}
@media screen and (min-width: 960px) and (max-width: 1450px){
.btnIcon1{
  width: 4.5rem;
}
.carouselicon1{
  height: 1.75rem;
  width: 1.75rem;
  align-self: center;
}
.titreES1{
  font-size: 0.7rem;
}
.conteneur2{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; 
  gap: 5rem; 
  margin-bottom: 2rem; 
  background-color: white; 
  padding: 1.5rem;
  border: 1px solid #289642;
  border-radius: 5px;
}
.bouton4{
  background-color: #289642;
  border: none;
  border-radius: 5px;
  height: 7vh;
  color: white;
  width: 22vw;
  font-weight: bold;
  font-size: 1rem;
}
.moitie1{
  width: 90%;
  display: block;
  justify-content: center;
  border-right: 1px solid black;
  padding: 1rem;
}
.reset{
  cursor: pointer;
  outline: 1px solid white;
  border-radius: 50%;
  box-shadow: 3px 3px 2px black;
  width: 6rem;
  height: 6rem;
}
.reset:hover{
  cursor: pointer;
  outline: 1px solid white;
  border-radius: 50%;
  box-shadow: 3px 3px 2px black;
  width: 7rem;
  height: 7rem;
}
.div-reset{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
.multiple-choix{
  width: 50%;
  font-size: 0.8rem;
}
.bouton-choix{
  background-color: #258BAF; 
  color: white; 
  width: 90%; 
  height: 6vh; 
  border-radius: 5px; 
  font-weight: bold;
  font-size: 0.8rem;
}
.rappel{
  text-align: center;
  font-size: 0.8rem;
}
}