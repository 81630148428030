@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;');
*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: 'Roboto',sans-serif;
}
.main { 
    flex:100%;
}
.blog-section{
    width: 100%;
}
.blog-section .section-content{
    width: 85%;
    margin:20px auto;
}
.blog-section .section-content .title{
    width: 60%;
    text-align: center;
    margin: auto;
}
.blog-section .section-content .title h2{
    font-size: 40px;
    color: #23d25d;
    text-transform: uppercase;

}
.blog-section .section-content .title p{
    font-size: 18px;
    color: #6a6a6a;
    margin-top: 20px;
}
.blog-section .section-content .cards{
    display:grid ;
    grid-template-columns: repeat(3,1fr);
    grid-gap:50px;
    margin: 25px auto;
}
.blog-section .section-content .cards .card{
   width: 100%;
   background-color: #f7f7f7;
   position: relative;
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.blog-section .section-content .cards .card img{
    width: 100%;
    height: auto;


}
.blog-section .section-content .cards .card .article{
    padding: 15px 20px;
}
.blog-section .section-content .cards .card .article h4{
    font-size: 24px;
    margin: 10px 0px;
    color: #6a6a6a;
}
.blog-section .section-content .cards .card button{
    text-decoration: none;
    display: inline-block;
    background-color: #258BAF;
    padding:8px 15px ;
    margin-left:20px ;
    color: #fff;
    margin-bottom: 20px;
    cursor: pointer;

    

}

.blog-section .section-content .cards .card .posted-date p{
    font-weight: 600px;
    position: absolute;
    top:0;
    left: 0;
    display: inline-block;
    background-color: #258BAF;
    color: #fff;
    padding:6px 15px ;
}
.carte1_senegal{
    width: 500px;
  height:00px;
  background: red;
  transition: width 2s, height 2s, transform 2s;
  cursor: pointer;
   
}
.carte1_senegal:hover {
    
    transform: rotate(180deg);
  }
.carte_senegal{
    /* width: 500px; */
    margin-left: 500px;
    height: 400px;
}
.heading {
    margin: 30px 0;
    background-image: url("./title_pattern.png");
    height: 35px;
    background-position: center;
  }
  .heading h6 {
    font-weight: 400;
    background: #258BAF;
    color: #fff;
    height: 35px;
    width: 130px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 14px;
  }
  .heading1 {
    margin: 30px 0;
    background-image: url("./title_pattern.png");
    height: 35px;
    background-position: center;
    margin-top: 0px;
  }
  .heading1 h6 {
    font-weight: 400;
    background: #258BAF;
    color: #fff;
    height: 35px;
    width: 130px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 14px;
  }
  .avatar {
    vertical-align: middle;
   
    height: 155px;
    border-radius: 50%;
    width:160px;
    box-shadow: 0 0 0 4px #fff;
    margin:-1.3em;
  }
  .avatar1{
    vertical-align: middle;
   
    height: 140px;
    border-radius: 50%;
    width:200px;
    box-shadow: 0 0 0 4px #fff;
    margin:-0.5em;
   
  }
  .avatar2{
    vertical-align: middle;
   
    height: 140px;
    border-radius: 50%;
    width:150px;
    box-shadow: 0 0 0 4px #fff;
   
   
  }
  .FlexContainer {
    margin-left: -4px;
    box-shadow: 0 0 0 4px #fff;
    align-items: center;
    display: flex;
    justify-content: center;
    
}
.container {
    display: flex;
    flex-direction: column;
}

.container__main {
    /* Take the remaining height */
    flex-grow: 1;

    /* Layout the left sidebar, main content and right sidebar */
    display: flex;
    flex-direction: row;
}

.container__left {
    width: 25%;
}

.container__middle {
    /* Take the remaining width */
    flex-grow: 1;
}

.container__right {
    width: 20%;
}
.carouselimg{ 
    height:auto;
    width: auto;
} 
.carouselicon{ 
    height: 3.125rem;
    width: 3.125rem;
    align-self: center;
} 
.carouselcontain{
    display: flex;
    flex-direction:row;
    justify-content: center;
    /* background-color:#f7e922; */
    align-items: center;
    gap: 1rem;
    height: 55vh;
    width: 100%;
}
.carouseldiv{ 
    display: flex;
    justify-content: center;
    align-items: center;
    flex:40%;
    width: 100%;
    height: 100%;
    /* background-color:#208001; */
    /* background-color:#f7e922; */
    border: 1.5px solid #208001;
}
.carouseldive{ 
    flex:50%;
    width: 400px;
    height: 400px;
    background-image: url("../components/images/imageDashbord.jpeg");
    background-color:white;
 
}

.roomfac {
    display: flex;
    width: 100%;
    max-width: 60%;
    margin: 0 auto;
    justify-content: center;
}

.shadow-lg{
    background-color: #258BAF;  
}
  
.textcarousel{ 
    color: #f7f7f7;
    padding: 10px ;
    text-align: center;
    font-style: italic;
    max-height: 370px;


}
.cardrowdomain{
  margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.letter{
    height: 54.5vh;
    width: 50%; 
}

.num{
    margin-left: 25%;
}

.carous{
    color: #fff;
    text-align: center;
}

.contain{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin: 2rem;
}

.span1{
    font-size: 1rem;
    font-weight: bold;
    color: #131c85;
}

.span2{
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
}

.communeDashboard{
    width: 100%;
    height: 100%;
    display: flex;
    justify-items: center;
    padding-top: 0;
    gap: 2%;
}

.commune{
    width: 91.5%;
    height: 70vh;
    margin-bottom: 4rem;
}

.labelRadio{
    cursor: pointer;
    text-align: start;
    font-weight: lighter;
    font-size: 1rem;
}

.inputRadio{
    margin: 1.2rem 0.8rem 1.2rem 0.8rem;
}

.sous-titre{
    font-weight: bold; 
    background-color: #289642;
    padding: 1rem;
    color: #fff;
}

.gros-titre{
    font-weight: bold; 
    background-color: #289642; 
    color:white;
}

.sous-titre1{
    font-weight: bold; 
    background-color: #289642; 
    font-size: 2rem; 
    padding: 1rem;
    color: #fff; 
    display: flex; 
    justify-content: center;
}

.icon{
    width: 2.5rem;
    height: 2.5rem;
    color: #289642;
}

.contact{
    font-weight: bold; 
    color: #289642;
}

.partenaire{
    margin-top: 1.5rem;
    width: 75%; 
    display: flex; 
    justify-content: center; 
    align-items: center;
}
.tabES{
    height: 85vh;
}
.btnIcon{
    width: 8rem;
}
.titreES{
    font-size: 1.4rem;
}
.card { 
    flex:50%;
    margin-bottom: 0.125rem;
    margin-right: 1.25rem;
    
}
.commune{
    width: 91.5%;
    height: 100vh;
    margin-bottom: 4rem;
}

@media screen and (min-width: 960px) and (max-width: 1450px){
    .carouselcontain{
        display: flex;
        flex-direction:row;
        justify-content: center;
        /* background-color:#f7e922; */
        align-items: center;
        gap: 0.5rem;
        height: 55vh;
        width: 100%;
    }
    .span1{
        font-size: 0.8rem;
        font-weight: bold;
        color: #131c85;
    }
    .letter{
        height: 54.5vh;
        width: 50%; 
    }
    .labelRadio{
        cursor: pointer;
        text-align: start;
        font-weight: lighter;
        font-size: 0.7rem;
    }
    .inputRadio{
        margin: 0.8rem 0.4rem 0.8rem 0.4rem;
    }
    .sous-titre{
        font-weight: bold; 
        background-color: #289642;
        padding: 1rem;
        color: #fff;
        font-size: 1.2rem;
    }
    .commune{
        width: 91.5%;
        height: 70vh;
        margin-bottom: 2.5rem;
    }
    .gros-titre{
        font-weight: bold; 
        background-color: #289642; 
        color:white;
        font-size: 1.6rem;
    }
    .sous-titre1{
        font-weight: bold; 
        background-color: #289642; 
        font-size: 1.5rem; 
        padding: 0.5rem;
        color: #fff; 
        display: flex; 
        justify-content: center;
    }
    .num{
        margin-left: 25%;
    }
    .contact{
        font-weight: bold; 
        color: #289642;
        font-size: 1.1rem;
    }
    .icon{
        width: 1.5rem;
        height: 1.5rem;
        color: #289642;
    }
    .partenaire{
        margin-top: 1rem;
        width: 65%; 
        display: flex; 
        justify-content: center; 
        align-items: center;
    }
    .tabES{
        height: 70vh;
    }
    .card { 
        flex: 25%;
        margin-bottom: 0.125rem;
        margin-right: 1.25rem;
    }
    .carouselicon{ 
        height: 1.75rem;
        width: 1.75rem;
        align-self: center;
    }
    .btnIcon{
        width: 6rem;
    }
    .titreES{
        font-size: 0.9rem;
    } 
    .commune{
        width: 91.5%;
        height: 100vh;
        margin-bottom: 2.5rem;
    }
}

@media (max-width: 960px) { 
    .communeinfo{ 
        display:flex;
        flex-direction:row;
        justify-content: center;
        align-items: center;
        height:100%;
        width:100%;
        }
        .infoscommune {
        flex: 50%;
        
        }
        .mapscommune{
        flex:50%;
        }
    .carouselcontain{ 
        flex-direction: column;
       
    }
    .carouseldiv{ 
        flex:50%;
        width: auto;
        height: auto;
    }
    .carouselimg{ 
        height: 400px;
        width: 400px;
    } 
    
    .textcarousel{ 
        color: #f7f7f7;
        padding: 6px ;
        font-style: italic;
        font-size: 9px;
    
    }
}



@media (max-width: 868px) { 
    
    
    .textcarousel{ 
        color: #f7f7f7;
        padding: 10px ;
        font-style: italic;
        font-size: 10px;
    
    
    }
}

.space { 
    height: 10px;
}

/* .backcontain{
    background-image: url("../components//images/photo_atelier_5.jpeg");
    height: 100%;
    background-repeat: no-repeat;

 
    display: grid;
    place-items: center;
} */

.cardDomain { 
    display: flex;
    flex:40%;
    justify-content: center;
    flex-direction:column;
    align-items: center;
    height:100%;
    width:100%;
    background-color:#208001;
    background-image:linear-gradient(rgba(40, 150, 66, 0.7), rgba(40, 150, 66, 0.7)),url('../components/images/boulel.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.communeinfo{ 
  margin-top: 2rem;
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;


}

.mapscommune{

flex:50%;

}
.infoscommune {
    flex:50%;
}

.arrow1{ 
    display: flex; 
    justify-content: center;
    align-items: center;
    height: 10vh;
    width: 91.5%;
    background-color: #289642;
    color: aliceblue;
    margin-bottom: 1.2rem;
    border-radius: 1rem;
}