.main {
  display: flex;
  background-color: white;
  flex-direction: column;
}
.containblog {
  display: flex;
  justify-content: center;
  background-color: white;
  flex-direction: row;
  align-items: center;

  margin: 10px;
}

.arrow {
  display: flex;
}
.cardDivideblog {
  flex: 10%;
  background-color: white;
}

.cardDemoblog {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex: 44%;
  background-color: #fff;
  border: 2px solid rgb(226, 213, 213);
  border: 2px solid #e7962d;
  border-radius: 50px 20px;
}

@media (max-width: 868px) {
  .containblog {
    display: flex;
    justify-content: center;
    background-color: white;
    flex-direction: column;
    align-items: center;
    margin: 20px;
  }
  .cardDemoblog {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex: 40%;
    height: 100%;
    width: 100%;
    background-color: #fff;
    margin-top: 10px;
    border-radius: 5px 5px;
  }
}
