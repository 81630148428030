.td_width {
  width: auto;
  height: 100%;
}
.editUser {
  cursor: pointer;

  font-size: 25px;
  /* padding: 5px; */
  background-color: #fff;
  border: none;
  /* border-color:red; */
}
.deleteUser {
  cursor: pointer;
  font-size: 25px;
  border: none;
  /* padding: 5px; */
  background-color: #fff;
  /* border-color:#fff */
}
.th{
  color: #289642;
}
.boxflex {
  display: flex;
}
/**
CARD STYLE
**/
.modalModif{
  display: flex;
}

.card_contain {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 13px;
}
.card_ {
  width: 380px;
  height: 380px;
  margin: 0px;
  box-shadow:  4px 4px 2px rgb(167 167 167);
}.card_:hover{
    /*transform: scale(0.9);  Équivalent à scaleX(0.7) scaleY(0.7) */
    background-color: #0404042a;
}
.card_img {
  padding: 20px;
  border: 1px solid #04040469;
  border-radius: 7px;
  border-bottom: none;
}
.card_body {
  width: 100%;
  padding-top: 0px;
  margin-top: -10px;
  border: 1px solid #04040469;
  border-top: none;
  border-radius: 7px;
}
.card_title {
  text-align: start;
  
}
.card_text {
    margin-bottom: 0px;
  color: black;
  height: 45px;
    overflow-y: scroll;
    margin-bottom: 10px;
}
.card_btn {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.deletebutton_{
  width:50%;
  height:42px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #bb2d3b;
  cursor: pointer;
  outline: 1px solid black;
}
.contient{
  width: 100%; 
  display: flex; 
  justify-content: end; 
  align-items: baseline;
  gap: 0.5rem;
  margin-bottom: 1rem;
}
.ajout-ressource{
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: white;
  width: 4vw;
  background-color: #289642;
  border-radius: 5px;
}
@media (max-width: 868px) { 
  .card_{
    box-shadow: none;
  }
  .card_:hover{
   /* transform: none;  Équivalent à scaleX(0.7) scaleY(0.7) */
    background-color: white;
   
}
.modalModif{
  flex-direction: column;
}
}
