.tableWrap {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
table th,
table td {
  border: 1px solid black;
  text-align: center;
  padding: 1px 15px;
  font-size: 20px;
  color: black;
}
td {
  font-size: 20px;
}

table {
  border-collapse: collapse;
}

table th {
  color: #e7962d;
  font-size: 20px;
}

table {
  border-collapse: collapse;
}

input {
  padding: 5px;
  /* margin-right: 5px; */
}

input[type="text"] {
  font-size: 20px;
  text-align: center;
  border-color: #316685;
}

input[type="password"] {
  font-size: 20px;
  text-align: center;
  border-color: #316685;
}
select {
  font-size: 20px;
  text-align: center;
  padding: 8.5px;
  border-color: #316685;
}
button {
  /* height: 50px;
  width: 80px; */
  cursor: pointer;
  text-align: center;
  font-size: 20px;
  padding: 5px;
  background-color: rgb(13, 150, 187);
  border-color: #316685;
}

.editUser {
  cursor: pointer;

  font-size: 25px;
  /* padding: 5px; */
  background-color: #fff;
  border: none;
  /* border-color:red; */
}
.deleteUser {
  cursor: pointer;
  font-size: 25px;
  border: none;
  /* padding: 5px; */
  background-color: #fff;
  /* border-color:#fff */
}
.deletebutton {
  background-color: red;
}
#form-group {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
#form-group label {
  flex: 1;
  flex-basis: 40%;
  max-width: 60%;
}
#form-group input {
  flex: 2;
  flex-basis: 40%;
  max-width: 60%;
}
#form-group select {
  flex: 2;
  flex-basis: 40%;
  max-width: 60%;
}
#form-group button {
  flex: 2;
  flex-basis: 40%;
  max-width: 60%;
}
/* .addForm { 
  justify-content: center;
  align-items: center;
} */

.mymodal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-top: "40px";
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

/* formulaires */
#form-group label {
  font-size: 17px;
  color: #343a40;
}
#form-group input,
#form-group select {
  font-size: 20px;
  width: 50vh;
  min-width: 47vh;
  outline: none;
  border: 1px solid #e7962d;
  color: #343a40;
}
#deleteBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #bb2d3b;
}
#deleteBtn,
#editBtn {
  text-transform: uppercase;
  color: white;
  outline: 1px solid none;
  font-size: 16px;
  width: 50%;
  height: 7vh;
}
