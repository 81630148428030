.para {
  font-size: medium;
  color: black;
}
.space {
  height: 100px;
}
.centrer {
  justify-content: center;
  text-align: center;
  background-color: #e7962d;
  color: white;
  align-items: center;
}

.imgacteuraphrc {
  width: 110px;
  height: 100px;
  border-radius: 50%;
  margin-left: 30px;
}
.imgacteurucad {
  width: 130px;
  height: 90px;
  border-radius: 50%;
  margin-left: 20px;
}
.imgacteurensa {
  width: 110px;
  height: 90px;
  border-radius: 50%;
  margin-left: 20px;
}

h1 {
  background-color: #e7962d;
  color: white;
}
