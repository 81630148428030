*{
    margin: 0;
    padding: 0;
  }
  .containersearch{
    display: flex;
    flex-direction: row;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    height: 4rem;
  }
  
  .igt {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    font-size: 1.5rem;
    font-weight: bold;
    animation: fadeIn 3s infinite;
  }
  
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  .div1{
    flex: 76%;
  
  }
  
  .div3{
    flex: 20%;   
  }
  .div2{
    flex: 4%;
  } 
  
  
  h2
  {
    color:#316685;
    
  }
  
  p{
    text-align: justify;
    font-size: 15px;
   
  }
  .atelier{
    background-color: #316685 !important;
  }
  
  .center{
    text-align: center;
    background-color:#316685 ;
    color: white;
  
  }
  .atelier-de-lancement{
    background-color: red;
    height: 50px;
  }
  
  .atelier1{
    box-shadow: 0px 0px 10px #000;
    width: 80%;
   
    margin: 40px auto;
    text-align: center;
    border-radius: 5px;
    /* background: url(); */
    align-self: cen;
  
  }
  .affichage{
    color: #000;
    font-size: 0.5em;
  }
  
  @media screen and (min-width: 960px) and (max-width: 1450px){
    .containersearch{
      display: flex;
      flex-direction: row;
      background-color: #fff;
      align-items: center;
      justify-content: center;
      height: 2.5rem;
    }
  
    .igt {
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;
      font-size: 0.9rem;
      font-weight: bold;
      animation: fadeIn 3s infinite;
    }
    
    
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
  
  @media (max-width: 960px) {
    .containersearch {
      flex: 100%;
      flex-direction: column;
    }
    .igt { 
      font-size: 8px;
      font-weight:bold
  
    }
  }
  
  
  