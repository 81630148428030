.table-container {
  width: 100%;
}

.horizontal-table {
  display: inline-block; /* Set display property to inline-block */
  width: 100%;
  border-collapse: collapse;
}

.horizontal-table th,
.horizontal-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.horizontal-table th {
  background-color: #f2f2f2;
}
